import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'

/**
 * App confirmation dialog component.
 *
 * Used to confirm user actions.
 *
 * @param {{
 *   open: boolean,
 *   title: string,
 *   content: string | React.ReactNode,
 *   disagreeLabel: string,
 *   agreeLabel: string,
 *   onConfirm: () => void,
 *   onCancel: () => void,
 * }} props - Mui Dialog props.
 *
 */
export default function BaseConfirmation({
  title,
  content,
  disagreeLabel,
  agreeLabel,
  onConfirm,
  onCancel,
  ...props
}) {
  return (
    <Dialog
      {...props}
      onClose={onCancel}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      maxWidth="xs"
      fullWidth
      keepMounted
      style={{ zIndex: 10001 }}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {typeof content === 'string' ? (
          <DialogContentText id="confirmation-dialog-description">
            {content}
          </DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" color="primary">
          {disagreeLabel}
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
          autoFocus
        >
          {agreeLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

BaseConfirmation.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  disagreeLabel: PropTypes.string,
  agreeLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

BaseConfirmation.defaultProps = {
  open: false,
  disagreeLabel: 'Cancelar',
  agreeLabel: 'Confirmar',
}
