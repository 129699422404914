const ICMS_SP = 0.82
const ICMS_SP_PERCENT = 18

const CALCULAR_PIS = false
const CALCULAR_COFINS = false
const CALCULAR_DIFAL = false

const toPercent = (value) => value / 100

/**
 *
 * @param {number} base
 * @param {number} aliquot
 */
const calculateTaxe = (base, aliquot) => {
  const value = base * toPercent(aliquot)

  return { value, total: base + value }
}

/**
 *
 * @param {number} price
 * @param {number} aliquotICMS
 * @param {number} aliquotIPI
 */
const calculateIPIwithRateDifference = (base, aliquotICMS, aliquotIPI) => {
  const baseSubtractedICMSPercent = base - base * toPercent(aliquotICMS)

  // Dividir calculo pela porcentagem padrão do estado
  const balanced = baseSubtractedICMSPercent / ICMS_SP
  const { value: ipi, total } = calculateTaxe(balanced, aliquotIPI)

  // Calcular a diferença do imposto
  const difal = total - base - ipi

  return { ipi, difal, total }
}

/**
 * @typedef Config
 * @prop {number} multi - Multiplicador usado para calcular os totais de cada imposto.
 * @prop {number} base - Base
 * @prop {number} IPI_PERCENTAGE - Aliquota do IPI (Imposto sobre Produtos Industrializados)
 * @prop {number} ICMS_PERCENTAGE - Aliquota do ICMS (Imposto sobre Circulação de Mercadorias e Serviço)
 * @prop {number} PIS_PERCENTAGE - Aliquota do PIS (Programa de Integração Social)
 * @prop {number} COFINS_PERCENTAGE - Aliquota do COFINS (Contribuição para o Financiamento da Seguridade Social)
 * @prop {boolean} disableCalculateRateDifference - Booleano que desabilita o cálculo do DIFAL (diferencial de aliquota)
 *
 * @param {Config} params
 * @returns
 */
export function calculateTaxes({
  multi,
  base,
  IPI_PERCENTAGE = 0,
  ICMS_PERCENTAGE = 0,
  PIS_PERCENTAGE = 0,
  COFINS_PERCENTAGE = 0,
  disableCalculateRateDifference = false,
}) {
  const x = (value) => value * multi

  const ignoreRateDifference =
    disableCalculateRateDifference ||
    [ICMS_SP_PERCENT, 0].includes(ICMS_PERCENTAGE)

  let result = {
    base,
    ipi: 0,
    icms: 0,
    pis: 0,
    cofins: 0,
    difal: 0,
    taxes: 0,
  }

  // IPI s/ICMS
  if (!CALCULAR_DIFAL && ignoreRateDifference) {
    const ipi = calculateTaxe(base, IPI_PERCENTAGE)

    result.ipi = ipi.value
    result.taxes = ipi.total
  } else {
    // IPI c/ICMS + DIFAL
    const { ipi, difal, total } = calculateIPIwithRateDifference(
      base,
      ICMS_PERCENTAGE,
      IPI_PERCENTAGE
    )

    result.ipi = ipi
    result.difal = difal
    result.taxes = total
  }

  // ICMS
  if (ICMS_PERCENTAGE) {
    const icms = calculateTaxe(base, ICMS_PERCENTAGE)

    result.icms = icms.value

    // Solicitado pela Ceral, a remoção da soma do valor do ICMS.
    // result.taxes += icms.value
  }

  // PIS
  if (CALCULAR_PIS && PIS_PERCENTAGE) {
    const pis = calculateTaxe(base, PIS_PERCENTAGE)

    result.pis = pis.value
    result.taxes += pis.value
  }

  // COFINS
  if (CALCULAR_COFINS && COFINS_PERCENTAGE) {
    const cofins = calculateTaxe(base, COFINS_PERCENTAGE)

    result.cofins = cofins.value
    result.taxes += cofins.value
  }

  return {
    ...result,
    ipi_total: x(result.ipi),
    icms_total: x(result.icms),
    pis_total: x(result.pis),
    cofins_total: x(result.cofins),
    difal_total: x(result.difal),
    base_total: x(result.base),
    taxes_total: x(result.taxes),
  }
}
