import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Tooltip, FormHelperText } from '@mui/material'
import { Cancel, LibraryAdd, SaveAlt } from '@mui/icons-material'

import TextField from '../../TextField'
import { apiEndPoints } from '../../../consts/apiEndPoints'
import api from '../../../services/api'
import handleErrors from '../../../services/handleErrors'
import {
  PaymentConditionComboboxFields,
  PaymentConditionComboboxContainer,
  PaymentConditionComboboxButtons,
} from './styles'

export default function PaymentConditionCombobox({ name, defaultValue }) {
  const [paymentConditions, setPaymentConditions] = useState([])
  const [loading, setLoading] = useState(true)

  const [isPreparingForCreate, setIsPreparingForCreate] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const [value, setValue] = useState(String(defaultValue))

  const newPaymentConditionRef = useRef(null)

  function fetchPaymentConditions() {
    api
      .get(apiEndPoints.paymentConditions)
      .then((response) => setPaymentConditions(response.data))
      .catch((error) => setErrorMessage(handleErrors(error)))
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchPaymentConditions()
  }, [])

  function handlePrepareCreate() {
    setIsPreparingForCreate(true)
  }

  function handleCancelCreate() {
    setIsPreparingForCreate(false)
    setErrorMessage(null)

    newPaymentConditionRef.current.value = ''
  }

  function handleCreate() {
    const paymentConditionDescription = newPaymentConditionRef.current.value

    if (paymentConditionDescription) {
      setLoading(true)

      api
        .post(apiEndPoints.paymentConditions, {
          payment_condition: {
            desc_condpagto: paymentConditionDescription,
          },
        })
        .then((response) => {
          const newPaymentCondition = response.data
          setPaymentConditions((state) => state.concat(newPaymentCondition))

          handleCancelCreate()
        })
        .catch((error) => setErrorMessage(handleErrors(error)))
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const paymentConditionOptions = paymentConditions.map((paymentCondition) => ({
    value: String(paymentCondition.id),
    label: paymentCondition.descr || String(paymentCondition.id),
  }))

  const handleChange = (e) => {
    const newValue = e.target.value
    setValue(newValue)
  }

  return (
    <PaymentConditionComboboxContainer>
      <PaymentConditionComboboxFields>
        <TextField
          disabled={loading}
          fullWidth
          name={name}
          value={value}
          onChange={handleChange}
          size="small"
          select
          SelectProps={{
            native: true,
          }}
        >
          {paymentConditionOptions.map((paymentCondition) => (
            <option key={paymentCondition.value} value={paymentCondition.value}>
              {paymentCondition.label}
            </option>
          ))}
        </TextField>

        {isPreparingForCreate && (
          <TextField
            inputProps={{
              ref: newPaymentConditionRef,
            }}
            placeholder="ex: 030/060/090 OU 030"
            name="desc_condpagto"
            size="small"
            error={Boolean(errorMessage)}
            fullWidth
          />
        )}

        <FormHelperText error={Boolean(errorMessage)}>
          {errorMessage?.msg}
        </FormHelperText>
      </PaymentConditionComboboxFields>

      <PaymentConditionComboboxButtons>
        {isPreparingForCreate && (
          <>
            <Tooltip title="Salvar condição de pagamento">
              <IconButton onClick={handleCreate} type="button" color="primary">
                <SaveAlt />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancelar">
              <IconButton
                onClick={handleCancelCreate}
                type="button"
                color="error"
              >
                <Cancel />
              </IconButton>
            </Tooltip>
          </>
        )}

        {!isPreparingForCreate && (
          <Tooltip title="Adicionar condição de pagamento">
            <IconButton
              onClick={handlePrepareCreate}
              type="button"
              color="primary"
            >
              <LibraryAdd />
            </IconButton>
          </Tooltip>
        )}
      </PaymentConditionComboboxButtons>
    </PaymentConditionComboboxContainer>
  )
}

PaymentConditionCombobox.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
