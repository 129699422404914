import { conformToMask } from 'react-text-mask'
import { cgc, phone } from '../consts/regex'

const LOCALE = 'pt-br'

const returnMaskFormat = (mask, value) => {
  return () => {
    switch (typeof value) {
      case 'string': {
        return conformToMask(value, mask).conformedValue
      }
      default:
        return value
    }
  }
}

const formats = (value) => ({
  toDecimal: ({ decimalCases = 2, ...other } = {}) => {
    if (!value) return value
    return `${Number(value).toLocaleString(LOCALE, {
      minimumFractionDigits: decimalCases,
      maximumFractionDigits: decimalCases,
      ...other,
    })}`
  },
  toPercent: (float) => {
    return parseFloat(value * 100).toFixed(float) + '%'
  },

  toSimpleDate: () => {
    switch (typeof value) {
      case 'string':
        return new Date(value).toLocaleDateString(LOCALE)
      default:
        return value
    }
  },
  toTimeDate: ({ ...options } = {}) => {
    switch (typeof value) {
      case 'string':
        return new Date(value).toLocaleTimeString(LOCALE, {
          ...options,
        })
      default:
        return value
    }
  },
  toCGC: returnMaskFormat(cgc.array, value),
  toPhone: returnMaskFormat(phone.array, value),
})

export const formatter = (value) => formats(value)
export default formatter
