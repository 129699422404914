import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { sum } from 'ramda'
import { Box, Typography, Button, useTheme, useMediaQuery } from '@mui/material'
import { ForwardToInboxOutlined as SendIcon } from '@mui/icons-material'
import { StyledGrid as Grid, Card } from './styles'

import { getQuantity, calculateTaxes } from '../../services/utils'
import { numberTextMaskProps, removeNumberMask } from '../../services/masks'

import TextField from '../TextField'
import FreightCombobox from '../Combobox/Freight'
import PaymentConditionCombobox from '../Combobox/PaymentCondition'
import Total from '../utils/Total'

const sumOf = (list) => (field) => sum(list.map((item) => item[field]))

const getSummary = (items) => {
  const prices = items.map((item) => {
    const unitOfMeasure = item.bidding_item?.unit_of_measure.id_un_medidas
    const disableCalculateRateDifference = item.ignore_rate_difference

    const multi = getQuantity(
      removeNumberMask(item.ptp_qtdade_atend),
      unitOfMeasure
    )

    return calculateTaxes({
      multi,
      base: removeNumberMask(item.ptp_valor_prev),
      IPI_PERCENTAGE: removeNumberMask(item.ptp_ipi_percent),
      ICMS_PERCENTAGE: removeNumberMask(item.ptp_icms_percent),
      PIS_PERCENTAGE: removeNumberMask(item.ptp_pis_percent),
      COFINS_PERCENTAGE: removeNumberMask(item.ptp_cofins_percent),
      disableCalculateRateDifference,
    })
  })

  const sumOfPrices = sumOf(prices)

  return {
    totalBase: sumOfPrices('base_total'),
    totalIPI: sumOfPrices('ipi_total'),
    totalICMS: sumOfPrices('icms_total'),
    totalDifal: sumOfPrices('difal_total'),
    totalPIS: sumOfPrices('pis_total'),
    totalCOFINS: sumOfPrices('cofins_total'),
    total: sumOfPrices('taxes_total'),
  }
}

export default function BiddingFinishForm(props) {
  const { onSendBidding, items, owner } = props

  const [freightValue, setFreightValue] = useState(owner.ftp_valor_frete)

  const summary = useMemo(() => {
    const availableItems = items.filter((item) =>
      [undefined, true].includes(item.available)
    )

    return getSummary(availableItems)
  }, [items])

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('md'))

  const numberMask = numberTextMaskProps()

  const handleSendBidding = (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const formValues = Object.fromEntries(formData)

    onSendBidding(formValues)
  }

  const handleChangeFreight = (e) => {
    const value = e.target.value
    setFreightValue(value)
  }

  return (
    <form onSubmit={handleSendBidding}>
      {/* ==== TOTAIS ==== */}
      <Grid container spacing={2} marginTop="0.5rem">
        <Total label="Total/Mercadoria" value={summary.totalBase} />
        <Total label="Total/IPI" value={summary.totalIPI} />
        <Total label="Total/ICMS" value={summary.totalICMS} />
        {/* <Total label="Total/Difal" value={summary.totalDifal} /> */}
        {/* <Total label="Total/PIS" value={summary.totalPIS} /> */}
        {/* <Total label="Total/COFINS" value={summary.totalCOFINS} /> */}
        {/* <Total label="Total" value={summary.total} /> */}
        <Total
          label="Total c/Frete"
          value={summary.total + removeNumberMask(freightValue)}
        />
      </Grid>

      {/* ==== COMUNS ==== */}
      <Grid container spacing={2} marginTop="0.5rem">
        <Grid item xs={12} md={4}>
          <Card>
            <Typography component="label" variant="caption">
              Condições de Pagamento
            </Typography>
            <PaymentConditionCombobox
              name="id_condpagto"
              defaultValue={owner.id_condpagto}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={2}>
          <Card>
            <Typography component="label" variant="caption">
              Frete por conta de:
            </Typography>
            <FreightCombobox name="ftp_frete" defaultValue={owner.ftp_frete} />
          </Card>
        </Grid>

        <Grid item xs={12} md={2}>
          <Card>
            <Typography component="label" variant="caption">
              Valor/Frete
            </Typography>
            <TextField
              fullWidth
              name="ftp_valor_frete"
              size="small"
              inputMode="decimal"
              value={freightValue}
              onChange={handleChangeFreight}
              textMaskProps={numberMask}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <Typography component="label" variant="caption">
              Observação
            </Typography>
            <TextField
              fullWidth
              name="ftp_obs"
              size="small"
              defaultValue={owner.ftp_obs}
              placeholder="ex:."
              multiline
            />
          </Card>
        </Grid>
      </Grid>

      <Box
        marginY="1rem"
        display="flex"
        justifyContent={isDesktop ? 'center' : 'flex-end'}
      >
        <Button
          type="submit"
          endIcon={<SendIcon />}
          variant="contained"
          disableElevation
          sx={{
            minWidth: '200px',
          }}
        >
          Enviar a Cotação
        </Button>
      </Box>
    </form>
  )
}

BiddingFinishForm.propTypes = {
  onSendBidding: PropTypes.func,
  total: PropTypes.number,
  items: PropTypes.array,
  owner: PropTypes.object,
}
