import styled from 'styled-components'

export const PaymentConditionComboboxContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;
  gap: 0.5rem;

  > div:first-child {
    flex: 1;
  }
`

export const PaymentConditionComboboxFields = styled.div``

export const PaymentConditionComboboxButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
