import React, { useState } from 'react'
import PropTypes from 'prop-types'
import BiddingPrice from '../BiddingPrice'
import BiddingPriceForm from '../BiddingPriceForm'

import { BiddingPriceContainer } from './styles'
import { useMediaQuery, useTheme } from '@mui/material'

function BiddingList(props) {
  const { biddingList, onUpdateList } = props

  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.down('md'))

  const [selectedBiddingPrice, setSelectedBiddingPrice] = useState(null)
  const [biddingPriceForCopy, setBiddingPriceForCopy] = useState(null)

  const handleUpdate = (biddingPrice) => {
    const updatedBiddingItems = biddingList.map((bp) =>
      bp.id_itens === biddingPrice.id_itens ? biddingPrice : bp
    )

    onUpdateList(updatedBiddingItems)
  }

  const handleEdit = (biddingPrice) => {
    setSelectedBiddingPrice(biddingPrice)
  }

  const handleCopy = (biddingPrice) => {
    setBiddingPriceForCopy(biddingPrice)
  }

  const handlePaste = (biddingPrice) => {
    const updatedBiddingPrice = { ...biddingPrice, ...biddingPriceForCopy }

    setBiddingPriceForCopy(null)
    handleUpdate(updatedBiddingPrice)
  }

  return (
    <BiddingPriceContainer>
      <table>
        <tbody>
          {biddingList.map((biddingPrice, idx) => (
            <BiddingPrice
              key={idx}
              onEdit={handleEdit}
              onCopy={handleCopy}
              onPaste={handlePaste}
              onUpdate={handleUpdate}
              biddingPrice={biddingPrice}
              biddingPriceForPaste={biddingPriceForCopy}
            />
          ))}
        </tbody>
      </table>
      {md && (
        <BiddingPriceForm
          open={Boolean(selectedBiddingPrice)}
          biddingPrice={selectedBiddingPrice}
          onClose={() => setSelectedBiddingPrice(null)}
          onSave={handleUpdate}
        />
      )}
    </BiddingPriceContainer>
  )
}

BiddingList.propTypes = {
  biddingList: PropTypes.array.isRequired,
  onUpdateList: PropTypes.func.isRequired,
}

export default BiddingList
