import React, { useState, useEffect } from 'react'
import { Slide } from '@mui/material'
import BaseConfirmation from '../BaseConfirmation'
import { events } from '../../../consts/events'
import client from '../../../services/eventEmitter'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const initialState = {
  title: '',
  content: '',
}

// ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
// ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

export const confirmation = {
  async open(title, content) {
    client.emit(events.confirmation.open, { title, content })

    return new Promise((resolve) => {
      client.once(events.confirmation.confirm, () => resolve(true))
      client.once(events.confirmation.cancel, () => resolve(false))
    })
  },
  close() {
    client.emit(events.confirmation.close)
  },
}

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

Transition.displayName = 'SlideTransition'

export default function Confirmation() {
  const [open, setOpen] = useState(false)
  const [dialog, setDialog] = useState({ ...initialState })

  const handleOpen = (options) => {
    setOpen(true)
    setDialog({
      title: options.title,
      content: options.content,
    })
  }

  const handleClose = () => {
    setOpen(false)
    setDialog({ ...initialState })
  }

  const handleConfirm = () => {
    client.emit(events.confirmation.confirm)
    setOpen(false)
  }

  const handleCancel = () => {
    client.emit(events.confirmation.cancel)
    setOpen(false)
  }

  useEffect(() => {
    client.on(events.confirmation.open, handleOpen)
    client.on(events.confirmation.close, handleClose)

    return () => {
      client.removeEventListener(events.confirmation.open, handleOpen)
      client.removeEventListener(events.confirmation.close, handleClose)
    }
  }, [])

  return (
    <BaseConfirmation
      {...dialog}
      TransitionComponent={Transition}
      open={open}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
  )
}
