import styled, { css } from 'styled-components'

const printStyles = css`
  @media print {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-bottom: 2px solid ${(props) => props.theme.palette.primary.main};
    margin-bottom: 1rem;
    background-color: #fff;

    th,
    td {
      padding: 0.35rem;
      display: block;
      width: 100% !important;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      border-bottom-color: ${(props) => props.theme.palette.grey[200]};
      border-radius: 0;
    }

    th {
      order: 1;
      grid-column: 1 / 5;
      display: flex;
      align-items: center;
    }

    td:nth-child(2) {
      order: 2;
    }

    td:nth-child(3) {
      /* order: 3; */
      order: 5;
      text-align: right;
    }

    td:nth-child(4) {
      order: 4;
      text-align: right;
    }

    td:nth-child(5) {
      /* order: 5; */
      order: 3;
      text-align: right;
    }

    td:nth-child(6) {
      /* order: 6; */
      order: 10;
      text-align: right;
    }

    td:nth-child(7) {
      /* order: 7; */
      /* order: 9; */
      order: 6;
      text-align: right;
    }

    td:nth-child(8) {
      /* order: 8; */
      /* order: 8; */
      order: 7;
      text-align: right;
    }

    td:nth-child(9) {
      /* order: 9; */
      /* order: 7; */
      order: 8;
      text-align: right;
    }

    td:nth-child(10) {
      /* order: 10; */
      /* order: 6; */
      order: 9;
      text-align: right;
    }

    td:nth-child(11) {
      order: 11;
    }

    td:nth-child(12) {
      order: 12;
    }

    input {
      direction: rtl;
      padding: 0;

      &:focus {
        direction: ltr;
      }
    }

    .MuiOutlinedInput-notchedOutline,
    .Mui-disabled.MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }

    h5,
    input {
      font-weight: 700;
      font-size: 16px;
    }
  }
`

export const BiddingPriceContainer = styled.tr`
  position: relative;
  background-color: ${(props) => props.theme.palette.grey[100]};

  th,
  td {
    padding: 1rem;
    border: 1px solid ${(props) => props.theme.palette.grey[200]};
  }

  th {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  td label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .MuiFormControl-root {
    background-color: #fff;
  }

  @media screen and (max-width: 799px) {
    td {
      display: none;
    }
  }

  ${(props) => {
    if (props.variant === 'unavailable') {
      return css`
        .MuiTypography-root {
          color: ${(props) => props.theme.palette.action.disabled};
        }
      `
    }
  }}

  ${printStyles};
`

export const BiddingPriceObservationContainer = styled(BiddingPriceContainer)`
  @media print {
    > td {
      grid-column: 1 / 5;

      textarea {
        color: ${(props) => props.theme.palette.grey[600]};
      }
    }
  }
`

export const TaxeCell = styled.td`
  width: 10%;
  min-width: 100px;

  &.hidden {
    display: none;
  }
`
