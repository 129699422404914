import React from 'react'
import PropTypes from 'prop-types'
import TextField from '../../TextField'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const FREIGHT_OPTIONS = ['CIF', 'FOB', 'RET']

export default function FreightCombobox({ name, defaultValue }) {
  return (
    <TextField
      fullWidth
      name={name}
      defaultValue={defaultValue}
      size="small"
      select
      SelectProps={{
        native: true,
      }}
    >
      {FREIGHT_OPTIONS.map((freight, index) => (
        <option key={index} value={freight}>
          {freight}
        </option>
      ))}
    </TextField>
  )
}

FreightCombobox.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
}
