import styled from 'styled-components'

export const TaxesContainer = styled.div`
  margin-top: 0.5rem;
`

export const TaxesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`
