import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { Card } from '../../BiddingFinishForm/styles'

const currencyFormatter = Intl.NumberFormat('pt-br', {
  style: 'currency',
  currency: 'BRL',
})

export default function Total({ label, value }) {
  return (
    <Grid item xs={12} md={3}>
      <Card
        sx={{
          alignItems: 'flex-end',
        }}
      >
        <Typography gutterBottom variant="body2">
          {label}
        </Typography>
        <Typography
          variant="h5"
          fontWeight="bold"
          textOverflow="ellipsis"
          overflow="hidden"
          maxWidth={200}
        >
          {currencyFormatter.format(value)}
        </Typography>
      </Card>
    </Grid>
  )
}

Total.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
