import styled from 'styled-components'
import { Container } from '@mui/material'

export const StyledContainer = styled(Container)`
  border-radius: 4px;
  overflow: hidden;
  /* max-width: calc(100vw - 4rem); */
  margin: 2rem 0;

  display: flex !important;
  flex: 1;
  flex-direction: column;

  min-height: 100vmin;
  background: ${(props) => props.theme.palette.background.paper};
  box-shadow: ${(props) => props.theme.shadows[5]};
  padding: 0 !important;

  section.main {
    min-height: calc(100vh - 180px - 150px);
    padding-left: 24px;
    padding-right: 24px;

    display: flex;
    flex-direction: column;
    overflow: auto;

    ${(props) => props.theme.breakpoints.down('md')} {
      align-items: center;

      padding-left: 12px;
      padding-right: 12px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  section.main.loading {
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
    z-index: 0;
  }

  @media print {
    margin: 0;
    box-shadow: none;

    section.main {
      min-height: 0;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
    }
  }
`

export const SupplierMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;

  > div {
    padding-left: 0.875rem;
    border-left: 2px solid ${(props) => props.theme.palette.divider};
  }

  @media screen and (max-width: 799px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  @media print {
    display: none;
  }
`
