import React from 'react'
import PropTypes from 'prop-types'
import MuiTextField from '@mui/material/TextField'
import TextMaskCustom from '../TextMaskCustom'

/**
 * @typedef {import('@mui/material/TextField').TextFieldProps} TextFieldProps
 *
 * @param {TextFieldProps} props
 * @returns {JSX.Element}
 */
function TextField({ textMaskProps, ...props }) {
  return (
    <MuiTextField
      {...props}
      inputProps={{
        ...props.inputProps,
        ...textMaskProps,
      }}
      InputProps={{
        inputComponent: textMaskProps ? TextMaskCustom : 'input',
        ...props.InputProps,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

TextField.propTypes = {
  textMaskProps: PropTypes.objectOf(PropTypes.any),
  inputProps: PropTypes.any,
  InputProps: PropTypes.any,
}

TextField.defaultProps = {
  textMaskProps: null,
}

export default TextField
