import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import theme from './styles/theme'
import GlobalStyle from './styles/global'

import Main from './pages/Main'
import Confirmation from './components/utils/Confirmation'

// import '@fontsource/roboto-slab'

function App() {
  return (
    <StyledThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        <Main />

        <Confirmation />
      </ThemeProvider>
    </StyledThemeProvider>
  )
}

export default App
