import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material'
import { ArrowDropUp } from '@mui/icons-material'

import { removeNumberMask, applyNumberMask } from '../../services/masks'
import { getQuantity, calculateTaxes } from '../../services/utils'
import { formatter } from '../../services/formatter'

import {
  BiddingPriceContainer,
  BiddingPriceObservationContainer,
  TaxeCell,
} from './styles'
import { BiddingPriceNumberField } from './NumberField'
import TextField from '../TextField'

export default function BiddingPrice({ onEdit, onUpdate, biddingPrice }) {
  const isMobile = useMediaQuery('@media only screen and (max-width: 899.95px)')

  const { bidding_item: biddingItem } = biddingPrice
  const available = biddingPrice.available

  const quantity = getQuantity(
    biddingPrice.ittp_qtdade_total,
    biddingItem.unit_of_measure.id_un_medidas
  )

  const availableQuantity = getQuantity(
    removeNumberMask(biddingPrice.ptp_qtdade_atend),
    biddingItem.unit_of_measure.id_un_medidas
  )

  const disableCalculateRateDifference = biddingPrice.ignore_rate_difference
  const disableSupplierTaxes = biddingPrice.disabled_supplier_taxes

  const getSummary = () => {
    return calculateTaxes({
      multi: availableQuantity,
      base: removeNumberMask(biddingPrice.ptp_valor_prev),
      IPI_PERCENTAGE: removeNumberMask(biddingPrice.ptp_ipi_percent),
      ICMS_PERCENTAGE: removeNumberMask(biddingPrice.ptp_icms_percent),
      PIS_PERCENTAGE: removeNumberMask(biddingPrice.ptp_pis_percent),
      COFINS_PERCENTAGE: removeNumberMask(biddingPrice.ptp_cofins_percent),
      disableCalculateRateDifference,
    })
  }

  const handleChange = (name) => (e) => {
    const value = e.target.value

    const updatedBiddingPrice = {
      ...biddingPrice,
      [name]: value,
    }

    onUpdate(updatedBiddingPrice)
  }

  const handleChangeAvailable = () => {
    onUpdate({ ...biddingPrice, available: !available })
  }

  const getInputProps = (name, { ...props } = {}) => ({
    name,
    size: 'small',
    onChange: handleChange(name),
    value: biddingPrice[name] ?? '',
    disabled: !available || props.disabled,
  })

  const summary = getSummary()

  return (
    <>
      <BiddingPriceContainer variant={available ? 'available' : 'unavailable'}>
        {/* === MATERIAL === */}
        <th scope="col" align="left" width="45%">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={available}
                onChange={handleChangeAvailable}
              />
            }
            label={
              <Typography variant="body1" fontWeight="bold">
                {biddingItem.dsc_materiais}
              </Typography>
            }
          />

          <Typography variant="body2" pl="28px" gutterBottom>
            {biddingItem.cod_mat}
          </Typography>

          {biddingItem.dsc_materiais_compl && (
            <Typography variant="body2" color="text.secondary" pl="28px">
              Complemento: {biddingItem.dsc_materiais_compl}
            </Typography>
          )}

          {isMobile && (
            <Button
              fullWidth
              variant="outlined"
              onClick={() => onEdit(biddingPrice)}
              endIcon={<ArrowDropUp />}
              sx={{
                marginTop: '1rem',
              }}
            >
              Informar preço
            </Button>
          )}
        </th>

        {/* === QUANTITY === */}
        <td align="right">
          <Typography component="label" variant="caption">
            Quantidade.
          </Typography>

          <Typography variant="h5">
            {formatter(quantity).toDecimal()}
          </Typography>
        </td>

        {/* === UN === */}
        <td align="left">
          <Typography component="label" variant="caption">
            Un.
          </Typography>

          <Typography variant="h5">
            {biddingItem.unit_of_measure.dsc_un_medidas}
          </Typography>
        </td>

        {/* === DELIVERY === */}
        <td width="8%">
          <BiddingPriceNumberField
            label="Prev.Entrega"
            type="number"
            placeholder="Dias"
            inputProps={{
              min: 0,
            }}
            {...getInputProps('ptp_prazo_entrega')}
          />
        </td>

        {/* === QUANTITY AVAILABLE === */}
        <td width="10%">
          <BiddingPriceNumberField
            label="Quantidade Atend."
            placeholder={biddingItem.unit_of_measure.dsc_un_medidas}
            {...getInputProps('ptp_qtdade_atend')}
          />
        </td>

        {/* === VALUE === */}
        <TaxeCell>
          <BiddingPriceNumberField
            label="Valor"
            placeholder="R$"
            {...getInputProps('ptp_valor_prev')}
          />
        </TaxeCell>

        {/* === IPI === */}
        <TaxeCell>
          <BiddingPriceNumberField
            label="% IPI"
            placeholder="%"
            {...getInputProps('ptp_ipi_percent', {
              disabled: disableSupplierTaxes,
            })}
          />
        </TaxeCell>

        {/* === ICMS === */}
        <TaxeCell>
          <BiddingPriceNumberField
            label="% ICMS"
            placeholder="%"
            {...getInputProps('ptp_icms_percent', {
              disabled: disableSupplierTaxes,
            })}
          />
        </TaxeCell>

        {/* === PIS === */}
        <TaxeCell>
          <BiddingPriceNumberField
            label="% PIS"
            placeholder="%"
            {...getInputProps('ptp_pis_percent', {
              disabled: disableSupplierTaxes,
            })}
          />
        </TaxeCell>

        {/* === COFINS === */}
        <TaxeCell>
          <BiddingPriceNumberField
            label="% COFINS"
            placeholder="%"
            {...getInputProps('ptp_cofins_percent', {
              disabled: disableSupplierTaxes,
            })}
          />
        </TaxeCell>

        {/* === VALUE === */}
        <td align="right">
          <Typography component="label" variant="caption">
            Valor Final
          </Typography>
          <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            maxWidth={150}
            variant="h5"
          >
            {applyNumberMask(summary.taxes)}
          </Typography>
        </td>

        {/* === TOTAL === */}
        <td align="right">
          <Typography component="label" variant="caption">
            Total
          </Typography>
          <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            maxWidth={150}
            variant="h5"
          >
            {applyNumberMask(summary.taxes_total)}
          </Typography>
        </td>
      </BiddingPriceContainer>

      <BiddingPriceObservationContainer
        variant={available ? 'available' : 'unavailable'}
      >
        <td align="right" colSpan={12}>
          <TextField
            fullWidth
            placeholder="Alguma informação adicional sobre o item ?"
            multiline
            rows={1}
            {...getInputProps('ptp_item_obs')}
          />
        </td>
      </BiddingPriceObservationContainer>
    </>
  )
}

BiddingPrice.propTypes = {
  biddingPrice: PropTypes.object.isRequired,
  biddingPriceForPaste: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

BiddingPrice.defaultProps = {}
