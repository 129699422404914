import { createNumberMask } from 'text-mask-addons'

const NumberFormat = new Intl.NumberFormat('pt-br', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const toMask = (mask, { ...options } = {}) => ({ mask, ...options })

export const unmask = (value) => value.replace(/[' '()/.-]/g, '').trim()

export const numberMask = (maskOptions) =>
  createNumberMask({
    prefix: '',
    decimalLimit: 2,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: true,
    requireDecimal: false,
    ...maskOptions,
  })

export const numberTextMaskProps = ({
  decimalPlaces = 2,
  ...maskOptions
} = {}) => ({
  mask: numberMask(maskOptions),
  pipe: (value) => {
    if (value.trim().length > 0) {
      return value
    } else {
      const [number = '0', decimal = '0'] = value.trim().split(',')
      return [number, decimal.padEnd(decimalPlaces, '0')].join(',')
    }
  },
})

export const applyNumberMask = NumberFormat.format

export const removeNumberMask = (value, fractionDigits = 2) => {
  if (typeof value === 'string')
    return Number(
      parseFloat(value.replaceAll('.', '').replace(',', '.')).toFixed(
        fractionDigits
      )
    )
  else return value
}
