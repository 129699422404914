import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

import TextField from '../TextField'
import { numberTextMaskProps } from '../../services/masks'

export function BiddingPriceNumberField({ label, disableMask, ...props }) {
  const numberMask = numberTextMaskProps()

  return (
    <>
      <Typography component="label" variant="caption">
        {label}
      </Typography>
      <TextField
        inputMode="decimal"
        textMaskProps={disableMask ? undefined : numberMask}
        {...props}
      />
    </>
  )
}

BiddingPriceNumberField.propTypes = {
  label: PropTypes.string,
  disableMask: PropTypes.bool,
}

BiddingPriceNumberField.defaultProps = {
  disableMask: false,
}
