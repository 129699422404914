import React from 'react'
import PropTypes from 'prop-types'
import { format, parseISO } from 'date-fns'
import { Box } from '@mui/system'
import { Typography, Grid } from '@mui/material'
import { AppBarContainer, StyledAppBar, StyledToolbar } from './styles'

import logoCeral from '../../assets/images/logo-ceral.png'

const gridItemProps = {
  item: true,
  md: 3,
  xs: 12,
  display: 'flex',
  flexDirection: 'column',
}

export default function AppBar(props) {
  const owner = props.owner

  const getBiddingDueDate = () => {
    return owner.bidding_due_date
      ? format(parseISO(owner.bidding_due_date), 'dd/MM/yyyy')
      : ''
  }

  return (
    <AppBarContainer>
      <StyledAppBar color="transparent" elevation={0} position="relative">
        <StyledToolbar>
          <Grid container direction="row">
            <Grid {...gridItemProps}>
              <img src={logoCeral} alt="" />
            </Grid>

            <Grid {...gridItemProps} md={2}>
              <Box>
                <Typography variant="body1" fontWeight={500}>
                  Cotação:
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  {owner.tpre_numero}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {format(new Date(), 'dd/MM/yyyy')}
                </Typography>
              </Box>
            </Grid>

            <Grid {...gridItemProps} md={5}>
              <Box>
                <Typography variant="body1" fontWeight={500}>
                  Fornecedor:
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {owner.emp_razao_social}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {owner.emp_endereco}
                </Typography>
              </Box>
            </Grid>

            <Grid {...gridItemProps} md={2}>
              <Box>
                <Typography variant="body1" fontWeight={500}>
                  Finaliza em:
                </Typography>
                <Typography variant="body1" fontWeight="bold" color="primary">
                  {getBiddingDueDate()}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  23:59:59
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </StyledToolbar>
      </StyledAppBar>
    </AppBarContainer>
  )
}

AppBar.propTypes = {
  owner: PropTypes.object,
}
