import React, { Suspense, useEffect, useState } from 'react'
import clsx from 'clsx'
import * as yup from 'yup'
import {
  Grid,
  Typography,
  Alert,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material'
import { Print } from '@mui/icons-material'

import apiEndPoints from '../../consts/apiEndPoints'
import api from '../../services/api'
import handleErrors from '../../services/handleErrors'
import { confirmation } from '../../components/utils/Confirmation'
import { applyNumberMask, removeNumberMask } from '../../services/masks'
import { getUrlKey } from '../../services/utils'

import AppBar from '../../components/AppBar'
import Loading from '../../components/Loading'
import BiddingList from '../../components/BiddingList'
import BiddingFinishForm from '../../components/BiddingFinishForm'
import { StyledContainer, SupplierMessageContainer } from './styles'

const Footer = React.lazy(() => import('../../components/Footer'))

const requiredOnAvailable = (min = 0, message) =>
  yup.number().when('ptp_indisponivel', {
    is: false,
    then: (scheme) => scheme.min(min, message).required(),
  })

const validateBiddingScheme = yup.object().shape({
  ftp_frete: yup.string().required(),
  ftp_obs: yup.string().optional(),
  ftp_valor_frete: yup.number().when('ftp_frete', {
    is: (ftp_frete) => ftp_frete === 'FOB',
    then: () => yup.number().required().min(0.01, 'Informe o valor do frete'),
  }),
  id_condpagto: yup.string().required(),
  supplier_id: yup.string().required(),
  prices: yup
    .array()
    .of(
      yup.object().shape({
        tpre_numero: yup.string().required(),
        id_cia: yup.number().required(),
        cod_fornecedor: yup.number().required(),
        id_itens: yup.number().required(),
        ptp_indisponivel: yup.boolean(),
        ptp_prazo_entrega: requiredOnAvailable(
          1,
          'Informe a Previsão de Entrega do item'
        ),
        ptp_valor_prev: requiredOnAvailable(0.01, 'Informe o valor do item'),
        ptp_ipi_percent: yup.number().nullable(),
        ptp_icms_percent: yup.number().nullable(),
        ptp_pis_percent: yup.number().nullable(),
        ptp_cofins_percent: yup.number().nullable(),
        ptp_item_obs: yup.string().nullable(),
      })
    )
    .min(1),
})

export default function MainPage() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [items, setItems] = useState([])
  const [owner, setOwner] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchBiddings = async () => {
      setErrorMessage(null)

      // Authentication
      api.defaults.headers['apikey'] = getUrlKey('k')

      try {
        const response = await api.get(apiEndPoints.biddingItems)
        const data = response.data

        if (data.length === 0) {
          setErrorMessage({
            severity: 'warning',
            msg: 'Nenhuma tomada de preço foi encontrada',
          })
          return
        }

        const formatTaxe = (value) => {
          const disabledTaxe = data[0].disabled_supplier_taxes
          return applyNumberMask(disabledTaxe ? '0' : value)
        }

        const items = data.map((item) => {
          return {
            ...item,
            ptp_valor_prev: applyNumberMask(item.ptp_valor_prazo),
            ptp_qtdade_atend: applyNumberMask(item.ptp_qtdade_atend),
            ptp_ipi_percent: formatTaxe(item.ptp_ipi_percent),
            ptp_icms_percent: formatTaxe(item.ptp_icms_percent),
            ptp_pis_percent: formatTaxe(item.ptp_pis_percent),
            ptp_cofins_percent: formatTaxe(item.ptp_cofins_percent),
            available: !item.ptp_indisponivel,
          }
        })

        const owner = items[0].supplier
        owner.tpre_numero = items[0].tpre_numero
        owner.bidding_due_date = items[0].bidding_due_date

        const biddingSupplier = items[0].bidding_supplier
        const freightValue = biddingSupplier.ftp_valor_frete

        owner.ftp_frete = biddingSupplier.ftp_frete
        owner.ftp_valor_frete = applyNumberMask(freightValue)
        owner.ftp_validade = biddingSupplier.ftp_validade
        owner.ftp_obs = biddingSupplier.ftp_obs
        owner.id_condpagto = biddingSupplier.id_condpagto

        setOwner(owner)
        setItems(items)
      } catch (error) {
        setErrorMessage(
          handleErrors(error, `Erro ao buscar a cotação "${error}"!`)
        )
      } finally {
        setLoading(false)
      }
    }
    fetchBiddings()
  }, [])

  const sendBidding = async (params) => {
    try {
      setLoading(true)

      const prices = items.map((price) => ({
        tpre_numero: price.tpre_numero,
        id_cia: price.id_cia,
        id_itens: price.id_itens,
        cod_fornecedor: price.cod_fornecedor,
        ptp_prazo_entrega: price.ptp_prazo_entrega,
        ptp_valor_prev: removeNumberMask(price.ptp_valor_prev),
        ptp_qtdade_atend: removeNumberMask(price.ptp_qtdade_atend),
        ptp_ipi_percent: removeNumberMask(price.ptp_ipi_percent),
        ptp_icms_percent: removeNumberMask(price.ptp_icms_percent),
        ptp_pis_percent: removeNumberMask(price.ptp_pis_percent),
        ptp_cofins_percent: removeNumberMask(price.ptp_cofins_percent),
        ittp_qtdade: price.bidding_item?.ittp_qtdade,
        ptp_indisponivel: price.available !== true,
        ptp_item_obs: price.ptp_item_obs,
        ittp_qtdade_total: price.ittp_qtdade_total
      }))

      const requestBody = {
        ftp_frete: params.ftp_frete,
        ftp_valor_frete: removeNumberMask(params.ftp_valor_frete),
        ftp_obs: params.ftp_obs,
        id_condpagto: params.id_condpagto,
        supplier_id: owner.cod_empresa,
        prices,
      }

      await validateBiddingScheme.validate(requestBody)

      await api.post(apiEndPoints.updateBiddingPrices, {
        bidding: requestBody,
      })

      setErrorMessage({
        severity: 'success',
        msg: 'Cotação enviada com successo',
      })

      setItems([])
      setOwner({})
    } catch (error) {
      handleBiddingError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleBiddingError = (error) => {
    if (error instanceof yup.ValidationError) {
      let message = 'Existem campos obrigatórios não preenchidos: '

      const index = error.path.match(/\b\d+\b/)?.at(0) || -1
      const invalidItem = error.value.prices[index]

      if (invalidItem) {
        const current = items.find(
          (item) => item.id_itens === invalidItem.id_itens
        )

        message += current?.bidding_item.dsc_materiais
        message += ' - '
      }

      message += error.message

      setErrorMessage({
        severity: 'warning',
        msg: message,
      })
    } else {
      const errorMessage = handleErrors(error, 'Erro ao enviar a cotação!')
      setErrorMessage(errorMessage)
    }
  }

  const handleSendBidding = async (params) => {
    const confirmed = await confirmation.open(
      'Envio tomada de preço',
      'Confirma os valores e o envio da cotação?'
    )

    if (confirmed) {
      sendBidding(params)
    }
  }

  const handlePrint = () => {
    window.print()
  }

  return (
    <StyledContainer maxWidth="xl">
      <AppBar owner={owner} />

      <section className={clsx('main', { loading })}>
        <Loading loading={loading} />
        <Grid
          container
          rowSpacing={1}
          direction="column"
          sx={{
            marginTop: '1rem',
          }}
        >
          <Grid item xs={12}>
            <SupplierMessageContainer>
              <div>
                <Typography
                  fontWeight={500}
                  color="primary.main"
                  variant="subtitle1"
                >
                  Caro Fornecedor,
                </Typography>
                <Typography gutterBottom variant="body2">
                  Favor informar o seu melhor preço para os materiais
                  relacionados abaixo. <br />
                  Caso não tenha disponibilidade para algum dos items, desmarque
                  a <i>flag</i> ao lado de seu nome.
                </Typography>
              </div>

              <Button
                startIcon={<Print />}
                variant="outlined"
                onClick={handlePrint}
              >
                Imprimir
              </Button>
            </SupplierMessageContainer>

            {errorMessage ? (
              <Alert
                severity={errorMessage.severity}
                onClose={() => setErrorMessage(null)}
                closeText="Fechar"
              >
                {errorMessage.msg}
              </Alert>
            ) : (
              isMobile && (
                <Alert severity="info">
                  Clique em cada Material/Serviço para inserir os dados.
                </Alert>
              )
            )}
          </Grid>
          <Grid item xs={12}>
            <BiddingList biddingList={items} onUpdateList={setItems} />
          </Grid>
        </Grid>
        {owner.cod_empresa && (
          <BiddingFinishForm
            owner={owner}
            items={items}
            onSendBidding={handleSendBidding}
          />
        )}
      </section>

      {owner.cod_empresa && (
        <Suspense fallback={null}>
          <Footer />
        </Suspense>
      )}
    </StyledContainer>
  )
}
