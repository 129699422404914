import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#425EA5',
    },
    secondary: {
      main: '#333',
    },
    success: {
      main: '#00B494',
    },
    background: {
      default: '#eaeaea',
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
    },
    caption: {
      fontSize: 10,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    h5: {
      fontSize: 24,
      fontWeight: 'bold',
    },
    button: {
      textTransform: 'none',
      fontWeight: 'bold',
      borderRadius: 9,
      height: 43,
    },
  },
})

export default theme
