// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const UNIT_OF_MEASURES_OPTIONS = {
  pieces: 2,
}

export const getUrlKey = (key) => {
  const param = new URLSearchParams(window.location.search).get(key)
  return param
}

export const getQuantity = (quantity, unitOfMeasure) => {
  if (unitOfMeasure === UNIT_OF_MEASURES_OPTIONS.pieces) {
    return parseInt(quantity, 10)
  }

  return quantity
}

export const compact = (values) =>
  values
    .filter(
      (item) => item !== undefined && item !== null && item.trim().length > 0
    )
    .join(' - ')

export { calculateTaxes } from './calculate-taxes'
