import styled from 'styled-components'

export const BiddingPriceContainer = styled.div`
  overflow: auto;
  width: 100%;

  table {
    width: 100%;
    border-spacing: 0 9px;
    border-collapse: separate;
  }
`
