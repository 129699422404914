import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Slide,
  Box,
  Button,
  Typography,
} from '@mui/material'
import TextField from '../TextField'
import { TaxesContainer, TaxesGrid } from './styles'

const TAXES_FIELDS = ['ipi', 'icms', 'pis', 'cofins']

const initialValues = {
  bidding_item: {},
  ptp_prazo_entrega: 0,
  ptp_valor_prev: '0',
  ptp_ipi_percent: '0',
  ptp_icms_percent: '0',
  ptp_pis_percent: '0',
  ptp_cofins_percent: '0',
  ptp_qtdade_atend: '0',
}

function BiddingPriceForm(props) {
  const { open, onClose, onSave } = props

  const [biddingPrice, setBiddingPrice] = useState(initialValues)
  const biddingItem = biddingPrice.bidding_item

  const disableSupplierTaxes = biddingPrice.disabled_supplier_taxes

  useEffect(() => {
    if (props.biddingPrice) {
      setBiddingPrice(props.biddingPrice)
    }
  }, [props.biddingPrice])

  const handleChange = (name) => (e) => {
    const value = e.target.value
    setBiddingPrice((previousValues) => ({
      ...previousValues,
      [name]: value,
    }))
  }

  const handleClose = () => {
    setBiddingPrice(initialValues)
    onClose()
  }

  const handleSave = () => {
    onSave(biddingPrice)
    handleClose()
  }

  const getInputProps = (name) => ({
    name,
    variant: 'standard',
    size: 'small',
    margin: 'normal',
    onChange: handleChange(name),
    value: biddingPrice[name],
  })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      keepMounted
      TransitionComponent={Transition}
    >
      <DialogTitle
        sx={{
          fontSize: '14px',
          textAlign: 'center',
          bgcolor: 'primary.light',
          color: 'primary.contrastText',
        }}
      >
        {biddingItem.dsc_materiais}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" mt={2}>
          <TextField
            variant="standard"
            size="small"
            margin="normal"
            label="Quantidade"
            type="number"
            disabled
            value={biddingPrice.bidding_item.ittp_qtdade || ''}
            InputProps={{
              endAdornment: (
                <Typography component="span" color="GrayText">
                  {biddingItem?.unit_of_measure?.dsc_un_medidas}
                </Typography>
              ),
            }}
          />
          <TextField
            {...getInputProps('ptp_qtdade_atend')}
            label="Quantidade Atendida"
            InputProps={{
              endAdornment: (
                <Typography component="span" color="GrayText">
                  {biddingItem?.unit_of_measure?.dsc_un_medidas}
                </Typography>
              ),
            }}
          />
          <TextField
            {...getInputProps('ptp_prazo_entrega')}
            label="Previsão de Entrega"
            type="number"
            InputProps={{
              endAdornment: (
                <Typography component="span" color="GrayText">
                  Dias
                </Typography>
              ),
            }}
          />
          <TextField
            {...getInputProps('ptp_valor_prev')}
            label="Valor Unitário"
            InputProps={{
              endAdornment: (
                <Typography component="span" color="GrayText">
                  R$
                </Typography>
              ),
            }}
          />

          <TaxesContainer>
            <Typography variant="subtitle1" fontWeight="bold" color="secondary">
              Impostos
            </Typography>

            {/* === TAXES === */}
            <TaxesGrid>
              {TAXES_FIELDS.map((taxeName) => {
                return (
                  <TextField
                    {...getInputProps(`ptp_${taxeName}_percent`)}
                    key={taxeName}
                    label={taxeName.toUpperCase()}
                    placeholder="(%)"
                    disabled={disableSupplierTaxes}
                    InputProps={{
                      endAdornment: (
                        <Typography component="span" color="GrayText">
                          %
                        </Typography>
                      ),
                    }}
                  />
                )
              })}
            </TaxesGrid>
          </TaxesContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button variant="outlined" onClick={handleSave}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

Transition.displayName = 'Transition'

BiddingPriceForm.propTypes = {
  open: PropTypes.bool.isRequired,
  bidding: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  biddingPrice: PropTypes.object,
}

export default BiddingPriceForm
