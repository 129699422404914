import styled from 'styled-components'
import { AppBar, Toolbar } from '@mui/material'

export const AppBarContainer = styled.nav`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  padding: 0.5rem 0.5rem 1.5rem;
  width: 100%;

  img {
    max-width: 250px;
    margin-bottom: 1rem;
  }
`

export const StyledAppBar = styled(AppBar)`
  .MuiGrid-item + .MuiGrid-item {
    border-left: 1px solid ${(props) => props.theme.palette.divider};
    padding-left: 30px;
    padding-right: 30px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    transform: none;

    .MuiGrid-item + .MuiGrid-item {
      padding-left: 0;
      padding-top: 15px;
      border-left: none;
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    img {
      max-width: 100px;
    }
  }
`

export const StyledToolbar = styled(Toolbar)`
  padding-top: 24px;
  display: flex;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding-bottom: 24px;
  }
`
