export const cgc = {
  get array() {
    return [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ]
  },
  get regex() {
    return /\d\d.\d\d\d.\d\d\d\/\d\d\d\d-\d\d/
  },
}

export const phone = {
  get array() {
    return [
      '(',
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]
  },
  get regex() {
    return /\(\d\d\) \d\d\d\d-\d\d\d\d/
  },
}
