import { lighten } from '@mui/material'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  body {
    background: rgb(0,28,99);
    background: linear-gradient(180deg, rgba(0,28,99,1) 0%, rgba(66,94,165,1) 41%);
  }

  @media (min-width: 600px) {
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: rgb(0,28,99);
      background: linear-gradient(180deg, rgba(0,28,99,1) 0%, rgba(66,94,165,1) 41%);
    }

    /* Track */
    ::-webkit-scrollbar-track {
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.palette.primary.light}; 
      border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${(props) =>
        lighten(props.theme.palette.primary.light, 0.1)}; 
    }
  }

  @media print {
    body {
      background: #fff;
    }

    button {
      display: none !important;
    }
  }
`

export default GlobalStyle
